import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/Clients/StephaneCatoire/Gastby/scatoire-perso/src/components/blog-post-layout.jsx";
import { graphql } from "gatsby";
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Excel everywhere`}</h1>
    <p>{`In my professional career, I had the opportunity to play with a large amount of reporting tools, nevertheless the preferred one for my colleagues was always Excel. On today’s world tools like Microsoft BI, Tableau or SAP Analytics seems to be the “saveur du jour” in business world but there will always be someone wanting to have the data in or from an Excel form.`}</p>
    <h1>{`The challenge`}</h1>
    <p>{`So, one day, I saw my colleague Roberto coming into my office as he wanted to create a map chart with bubble the size of payables amount. The problem is that for many of these tools, you need a latitude and longitude coordinates, and he only had a worksheet with something like a thousand addresses.`}</p>
    <h1>{`Google maps`}</h1>
    <p>{`At the time, I was using the Google maps API, which provides the longitude and latitude based on an address query. The problem is that nowadays using google maps API has become a bit quirky since you first need to register to the API to get a key. Also, this API is no longer free, and when you work for a business stingier than Mr Burns injected with Duck McScrooge DNA and when you have an IT department so inept that you have to work against it instead of with it to get things done, you need to be creative.`}</p>
    <h1>{`Nominatim`}</h1>
    <p>{`So, let’s have a look to OpenStreetMap. OpenStreetMap is a collaborative project to create a free editable geographic database of the world, and there is a free API, Nominatim, that you can use to find GPS coordinates related to text addresses. You can find more details here: `}<a parentName="p" {...{
        "href": "https://nominatim.org/"
      }}>{`https://nominatim.org/`}</a>{` and query it here: `}<a parentName="p" {...{
        "href": "https://nominatim.openstreetmap.org/ui/search.html"
      }}>{`https://nominatim.openstreetmap.org/ui/search.html`}</a>{`. Note that while using this API is free, the terms of use require a maximum of 1 request per second, so don’t use it to map your entire LFA1 table please, but if you have a few thousand items, it should do the job.`}</p>
    <h1>{`Excel Macro`}</h1>
    <p>{`You can use Excel to query web API directly, but the trick here is that we need to put the address we want to query in the URL. It might be possible to do with a dedicated power query, but the easier is still to write a dedicated vba macro.`}</p>
    <h2>{`Accessing the VBA editor`}</h2>
    <p>{`You can access the vba editor with alt-F11, or via the macro button in the developer tab. If the developer bar is not visible, select File on the menu, then on the File tab, go to Options > Customize Ribbon; under Customize the Ribbon and under Main Tabs, select the Developer check box.`}</p>
    <h2>{`Create a new module`}</h2>
    <p>{`In the editor, create a new module, by selecting “insert”, then “module”.`}</p>
    <h2>{`Adding a reference to XML library`}</h2>
    <p>{`Then go in the “tools” toolbar and select references. Then look after the Microsoft XML reference, as indicated below
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "513px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d4da35ea25bc9ca96b118dbc4bed6d3e/267f6/References-VBA-project.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.50306748466258%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVQ4y3WTy47TMBiF86xsYMkKIdgh8QDskUDskBAX8QawYaZtekni3G0nrh07vuWCSjotDUrLSIyAT96e/5zfPnaEEIxSrU3bNiUhlNGu68dxPJ1O4/85Hg774YejpKKUVmc4503Tfv8/Xf+dqWYrFBGam9bZEhJHEYIoP2OMaW4xxl7OmaaxVigzi+nXBfiySjeQO7goMMIYYowwpRQEAASgYny32+33+2G/PxwO1lrGqq7rrLUF2bprb+UBLoQDAFgv1wt3dX01C8NYCFWU26oSXMif43g8b66NwRhfxBVjeZYWGEspHYQwjCOYREngEZiJbUHyVBBck+L08zCOk3i/H5RSXdc1TQMhnM9mAICKc6csixcfZ4/efHv+YfPso//kXXDv5fWD14v7rxaPP8Gnn9HD9/DtVTn0DSoKLnh/5jLIEYIvvejbErhBkhGREoG4Tbc1kZ3oBmZ31O5q22ut0iSllBpj6rqWUhpjHK0VzNP51fVyPlu7buT7MEkS34/DkJaFqFijldFKaw1zSLeUMbZ0XQAA59yRUkKEfM/brDZRGMEMlgVJ4ySO4jyDN8fjOI7DMBhry7LkQrRta2+ZnEEA3Lnrbzxv7adxWuACQ6SUurm5ufRpGAaplDm/ePMHk3MIwvlsEQLge57vBXmWaaWOZ8/j8Xg6nfq+50J0bdvcxRFCZFnm+b4fgCiOAQj9ICCESKWmRbVRWleca62bv3AYZVEYJUmKIEIYW2unDnddc9fG3Lb0T5xpuDF5loNgukCEUAjCJEmiMIyjOEnTS/PbvzJPYmtt33dZmq3dFTl/ks16QxlTSkkp1ZRe/TPzb7G1Vmtd17VWSptpUWvtXSv7T34BXIeVu8i0WmIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Reference a vba project",
            "title": "Reference a vba project",
            "src": "/static/d4da35ea25bc9ca96b118dbc4bed6d3e/267f6/References-VBA-project.png",
            "srcSet": ["/static/d4da35ea25bc9ca96b118dbc4bed6d3e/222b7/References-VBA-project.png 163w", "/static/d4da35ea25bc9ca96b118dbc4bed6d3e/ff46a/References-VBA-project.png 325w", "/static/d4da35ea25bc9ca96b118dbc4bed6d3e/267f6/References-VBA-project.png 513w"],
            "sizes": "(max-width: 513px) 100vw, 513px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`The VBA Code`}</h2>
    <p>{`We will write a macro that will return a matrix with latitude, longitude, and if there is nothing corresponding in the database, an error message.
Copy the VBA code below in the module you created.`}</p>
    <pre><code parentName="pre" {...{}}>{`Function AddressToCoordinates(address As String) As Variant
Const APIPREFIX = "https://nominatim.openstreetmap.org/search?format=xml&q="
Dim returnValue(2) As String
Dim Xdoc As New MSXML2.DOMDocument60
    Xdoc.async = False
    Xdoc.Load (APIPREFIX + WorksheetFunction.EncodeURL(address))
    If Xdoc.parseError.ErrorCode <> 0 Then
        AddressToCoordinates(2) = Xdoc.parseError.reason
    Else
        Xdoc.SetProperty "SelectionLanguage", "XPath"
        Dim loc As MSXML2.IXMLDOMElement
        Set loc = Xdoc.SelectSingleNode("/searchresults/place")
        If loc Is Nothing Then
            returnValue(2) = Xdoc.XML
        Else
            returnValue(0) = loc.getAttribute("lat")
            returnValue(1) = loc.getAttribute("lon")
        End If
    End If
    AddressToCoordinates = returnValue
End Function
`}</code></pre>
    <h1>{`In the Excel workbook`}</h1>
    <p>{`I found on the internet a list of fictional places, that we can use to test our function. The first column contains the address, the second the owner of this address. We will add three other columns for latitude, longitude and a third one for the eventual error messages.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/bc3ae/ExcelAddresses.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.263803680981596%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABTElEQVQY03WO207CQBRF+/9foy+GBEW8UJQI2svMkNq0dhDBmc617bSdIhgwJqhxPezkPKy9jzOYXvfc/pk7uJzd9maTk/vx5ePoynP7k+HpTT+M5gl+GcGH/tS9mN5e+5Pz2Wjo3Y3njy6aOZUqVJ7nhFa6LFTJmaRropjQQnLK3l6Xq9VKS1WqolSFFsqapq3q1jStaRy8wOv3d8YZoYQLzgXPsoxQygVXWmmthRCccyEEIeQr94eUQkrHe/KCMIyiKM+ZtV1Vlr7nxXFMKdVaFwfsgbZpuq5r27b7xgkCHyIYx88YY2ttbQwEIEnSPM+VUvVB2PyDA0AIAEiThFK62WyMMQjBKIowxpQSpZW19lg47nLCIEAQpWlaVdVutzPGQAhDAF6yTErZ/Fn+Ifu+j+Z7eblcSim11miOFosFY6yu64/t9terx/InchW3NZBe9VgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ExcelAddress",
            "title": "ExcelAddress",
            "src": "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/a6d36/ExcelAddresses.png",
            "srcSet": ["/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/222b7/ExcelAddresses.png 163w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/ff46a/ExcelAddresses.png 325w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/a6d36/ExcelAddresses.png 650w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/e548f/ExcelAddresses.png 975w", "/static/9f2147aa9cc6ba0fdcaf5c38e0742e33/bc3ae/ExcelAddresses.png 1268w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We will then use the function we just created. Select the range C2 to E2 like in our example.
Type in the Excel formula: =ADDRESSTOCOORDINATES(A2) and then CTRL+SHIFT+ENTER since this is a matrix formula.
Copy down the columns C,D and E
And check the final result:`}</p>
    <p>{`As you can see, in the table above, you will not be able to send a postcard to SpoungeBob SquarePants at 124 Conch St., Bikini Bottom, Pacific Ocean, but 1329 Carrol avenue, Home of the Halliwell family of “Charmed” fame, exists at the given coordinates of 34.0697 -118.254.`}</p>
    <p>{`I hope you find this article interesting, and that it will be of use for you.
Have a nice day,
Stéphane Catoire.`}</p>
    <p>{`References:
HTTP://WWW.MKRGEO-BLOG.COM/THE-COSTLESS-WAY-TO-GEOCODING-ADDRESSES-IN-EXCEL-PART-3-BULK-DATA-GEOCODING-WITH-NOMINATIM-AND-OTHERS-GEOCODING-TOOLS/`}</p>
    {
      /* Footer to allow translation to be copied on each post
          Boring but wainting for a better solution */
    }



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      